import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "MDc3ZDAyZGQ5ZWE5YWM2MzEzMTAxOGJiYTZjMGUwOTg=",
	"Tenant-Access-Key": "M6zFhTcdFZpo4A",
};

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
